<template>
   <div>
      <div class="authentication bg-dark">

         <div id="particles-js"></div>
         <div class="countdown-bg"></div>

         <div class="error-screen">
            <h1>404</h1>
            <h5>Sentimos muito!<br/>A pagina que você está requisitando não pode ser encontrada.</h5>
            <a @click="$router.push('/')" class="btn btn-primary">Voltar para pagina principal</a>
         </div>

      </div>
   </div>
</template>

<script>
export default {
   name: "error404"
}
</script>

<!--<script src="js/jquery.min.js"></script>-->
<!--<script src="js/bootstrap.bundle.min.js"></script>-->
<!--<script src="js/moment.js"></script>-->

<!--<script src="vendor/particles/particles.min.js"></script>-->
<!--<script src="vendor/particles/particles-custom-error.js"></script>-->
<style scoped>

</style>