import {http} from './config';

export default {
    index(groupId, filter = null, page = 1, per_page = null, isExport = false, orderP = null, orderM = null) {
        if (per_page === 'default'){
            per_page = null;
        }
        let ob = {};
        if (orderP){
            ob.price = orderP
        }
        if (orderM){
            ob.total_area = orderM
        }
        return http.post(`api/v1/publica/listaLotesPorGrupoCompanhias/${groupId}?page=${page}`,
            {filter: [{...filter}], per_page, "export":isExport, 'ordenation': [ob]});
    },
    citiesList(groupId, filter = null) {
        return http.post(`api/v1/publica/listaCidadesPorGrupoCompanhias/${groupId}`, {filter: [{...filter}]});
    },
    allotmentsList(groupId, filter = null) {
        return http.post(`api/v1/publica/listaLoteamentosPorGrupoCompanhias/${groupId}`, {filter: [{...filter}]});
    },
    blockList(groupId, filter = null) {
        return http.post(`api/v1/publica/listaQuadrasPorGrupoCompanhias/${groupId}`, {filter: [{...filter}]});
    },
    minMaxList(groupId, filter = null){
        return http.post(`api/v1/publica/listaMinMaxPorGrupoCompanhias/${groupId}`, {filter: [{...filter}]});
    },
    lotList(groupId, filter = null) {
        return http.post(`api/v1/publica/listarNumeroLotePorGrupoCompanhias/${groupId}`, {filter: [{...filter}]});
    },
    listAllotmentsDataMap(id){
        return http.post(`api/v1/publica/listaMapasLoteamentosPorGrupoCompanhias/${id}`);
    },
}